<template>
  <div class="widgetContainer__body">
    <div class="widgetContainer__header-fixed d-sm-none d-flex">
      <div class="headerExport">
      </div>
    </div>
    <v-app class="vue-app">
      <v-main>
        <v-container fluid>
					<v-data-table
            :headers="tableHeader"
            :items="tableData"
            class="elevation-1 v-table-middle-align"
            :single-select="false"
            show-select
            v-model="selectedAccounts"
            :loading="isAccountsDataLoading"
            disable-pagination
            :hide-default-footer="true"
          >
            <template v-slot:top>
              <div class="d-block d-sm-flex d-md-flex d-lg-flex pa-4">
                <div class="mr-auto">
                  <p class="header-1 mb-0 pt-2">{{ $t('side_menu_accounts') }}</p>
                </div>
                <div class="d-sm-flex d-inline">
                  <v-text-field
                    v-model="searchText"
                    :label="$t('search_accounts')"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    hide-details
                    flat
                    solo
                    outlined
                    dense
                  ></v-text-field>
                  <div class="ml-sm-2 ml-0 mt-2 mt-sm-0">
                    <v-btn
                      class="form-border text-capitalize"
                      color="primary"
                      @click="onToggleAccountForm"
                      height="38"
                      block
                    >
                      {{ $t('add_account') }}
                    </v-btn>
                  </div>
                </div>
              </div>
              <v-divider/>
            </template>
            <template v-slot:[`item.enabled`]="{ item }">
              <v-btn small rounded outlined
                :color="item.enabled ? 'success': 'error'"
                class="pa-1"
                :loading="selectedAccountSyncingIds.includes(item.id)"
                @click="onChangeEnabledValue(item)"
              >
                <div v-if="item.enabled" class="d-flex align-center justify-space-around" style="min-width: 60px">
                  {{ $t('yes') }}
                  <v-icon>mdi-check-circle</v-icon>
                </div>
                <div v-else class="d-flex align-center justify-space-around" style="min-width: 60px">
                  <v-icon>mdi-minus-circle</v-icon>
                  {{ $t('no') }}
                </div>
              </v-btn>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <h6
                    v-bind="attrs"
                    v-on="on"
                    v-text="item.email"
                  />
                </template>
                <span>{{ item.phone }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" primary v-on="on" color="primary" text outlined small>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    @click="onSelectEditAccountData(item)"
                  >
                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onSelectAccountInformation(item)"
                  >
                    <v-list-item-title>{{ $t('view') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onSelectAccountToDelete(item)"
                  >
                    <v-list-item-title class="red--text">{{ $t('delete') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:no-data>
              <v-alert>
                <h4>{{ $t('no_data') }}</h4>
                <v-btn text small @click="onToggleAccountForm" color="#0889a0" class="mb-2 font-weight-bold py-4 text-capitalize">
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('add_account') }}
                </v-btn>
              </v-alert>
            </template>
            <template v-slot:foot>
              <tfoot>
                <tr>
                  <td colspan="7" class="text-right">
                    <div class="d-flex justify-center justify-sm-end">
                      <!-- <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{pagination.current_page || 0}} - {{ pagination.total_pages || 0 }} {{ $t('of') }} {{ (pagination.total || 0).toLocaleString() }} <span class="d-sm-inline d-none">{{ $t('records') }}</span></h6> -->
                      <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{ (pagination.total || 0).toLocaleString() }} <span class="">{{ $t('records') }}</span></h6>
                      <v-spacer/>
                      <div class="select-page-limit">
                        <v-select
                          v-model="pageLimit"
                          :items="[10, 20, 25]"
                          label="Per page"
                          hide-details
                          dense
                          flat
                          solo
                          @change="onChangePageLimit"
                        ></v-select>
                      </div>
                      <pagination
                        :total="pagination.total_pages || 1"
                        :current-page="currentPage"
                        :total-visible="10"
                        @pageSelected="onPaginationClick"
                      >
                      </pagination>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-data-table>
          <v-snackbar
            timeout="2000"
            vertical
            :color="snackbarOption.color"
            v-model="showSnackbar"
            bottom
          >
            <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
          </v-snackbar>
          <account-form :open="showFormDialog" :is-edit-mode="isEditMode" :form-values="selectedAccountData" @toggle="onToggleAccountForm"></account-form>
          <account-delete :open="showDeleteModal" :form-data="selectedDataToDelete" @toggle="onToggleDeleteAccountForm"></account-delete>

          <v-dialog
            v-model="showInfoModal"
            max-width="980"
            origin="top center"
            class="dialog pa-0"
            persistent
            scrollable
          >
            <v-card>
              <v-card-title class="pa-0">
                <v-toolbar light elevation="1" class="">
                  <v-toolbar-title>{{ selectedAccountInformation.name }}</v-toolbar-title>
                  <v-spacer></v-spacer>

                  <v-btn class="d-none d-sm-flex" color="primary" @click="onSelectEditAccountData(selectedAccountInformation)">
                    {{  $t('Edit') }}
                  </v-btn>
                  <v-btn text outlined class="ml-1 mr-4 d-none d-sm-flex" @click="onSelectAccountToDelete(selectedAccountInformation)">
                    {{ $t('delete') }}                    
                  </v-btn>
                  <v-btn icon class="d-none d-sm-flex" @click="onToggleAccountInformationModal">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-card-title>
              <v-card-text class="py-4 pb-8 grey lighten-3">

                <v-sheet light elevation="2" class="pa-6">
                  <v-row>
                    <v-col sm="4"ols="12">
                      <h6 class="subtitle-1 labelcolor--text">{{ $t('name') }}</h6>
                      <span>{{ selectedAccountInformation.name }}</span>
                    </v-col>
                    <v-col sm="4" cols="12">
                      <h6 class="subtitle-1 labelcolor--text">{{ $t('number') }}</h6>
                      <span>{{ selectedAccountInformation.number }}</span>
                    </v-col>
                    <v-col sm="4" cols="12">
                      <h6 class="subtitle-1 labelcolor--text">{{ $t('currencyname') }}</h6>
                      <span>{{ currencyObj.name }}</span>
                    </v-col>
                    <v-col sm="4" cols="12">
                      <h6 class="subtitle-1 labelcolor--text">{{ $t('opening_balance') }}</h6>
                      <span>{{ selectedAccountInformation.opening_balance_formatted }}</span>
                    </v-col><v-col sm="4" cols="12">
                      <h6 class="subtitle-1 labelcolor--text">{{ $t('current_balance') }}</h6>
                      <span>{{ selectedAccountInformation.current_balance_formatted }}</span>
                    </v-col>
                    <v-col sm="4" cols="12">
                      <h6 class="subtitle-1 labelcolor--text">{{ $t('bank_name') }}</h6>
                      <span>{{ selectedAccountInformation.bank_name }}</span>
                    </v-col>
                    <v-col sm="4" cols="12">
                      <h6 class="subtitle-1 labelcolor--text">{{ $t('bank_phone') }}</h6>
                      <span>{{ selectedAccountInformation.bank_phone }}</span>
                    </v-col>
                    <v-col sm="4" cols="12">
                      <h6 class="subtitle-1 labelcolor--text">{{ $t('bank_address') }}</h6>
                      <span>{{ selectedAccountInformation.bank_address }}</span>
                    </v-col>
                    <v-col sm="4" cols="12">
                      <h6 class="subtitle-1 labelcolor--text">{{ $t('enabled') }}</h6>
                      <span>{{ selectedAccountInformation.enabled ? $t('yes') : $t('no') }}</span>
                    </v-col>
                  </v-row>
                </v-sheet>
                
                <v-expansion-panels class="mt-6">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span class="labelcolor--text">{{ $t('transactions') }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="transactionTableHeader"
                        :items="transactionLists"
                        class="elevation-1 v-table-middle-align"
                        :loading="isFetchingTransactions"
                      >
                        <template v-slot:item.paid_at="{item}">
                          <span>{{ moment(item.paid_at, 'YYYY-MM-DD').format('DD MMM YYYY') }}</span>
                        </template>
                        <template v-slot:item.vendor="{item}">
                          {{ item.contact.data.name }}
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
              <v-divider class="d-flex d-sm-none"/>
              <v-card-actions class="d-flex d-sm-none">
                <v-btn color="primary" @click="onSelectEditAccountData(selectedAccountInformation)">
                    {{  $t('Edit') }}
                  </v-btn>
                  <v-btn text outlined class="ml-1 mr-4" @click="onSelectAccountToDelete(selectedAccountInformation)">
                    {{ $t('delete') }}                    
                  </v-btn>
                  <v-spacer/>
                  <v-btn @click="onToggleAccountInformationModal" elevation="0">
                    close
                  </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
				</v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import moment from 'moment';
import { mapActions } from 'vuex';
import AccountForm from '../components/AccountForm.vue';
import AccountDelete from '../components/AccountDeleteModal.vue';
import Pagination from '../components/Pagination.vue';
import { apiErrorMessage, debounce } from '@/utils/general';
import AkauntingService from '@/services/akaunting/akaunting.service';

export default {
  components: {
    AccountForm,
    AccountDelete,
    Pagination,
  },

  data() {
    return {
      showInfoModal: false,
      isAccountTogglingProgress: false,
      isAccountsDataLoading: false,
      accountLists: [],
      page: 1,
      showDeleteModal: false,
      selectedAccountData: {},
      selectedAccountInformation: {},
      selectedDataToDelete: {},
      isEditMode: false,
      searchText: '',
      selectedAccounts: [],
      showFormDialog: false,
      showSnackbar: false,
			snackbarOption: {
				icon: '',
				message: '',
				color: ''
			},
      currentPage: 1,
      pageLimit: 10,
      currencyList: [],
      selectedAccountSyncingIds: [],
      accountTransactions: {
        data: [],
        meta: {}
      },
      isFetchingTransactions: false,
    };
  },

  created() {
    this.fetchCurrencies();
    this.fetchAccountsData();
  },

  watch: {
    searchText: debounce(function() {
      this.fetchAccountsData();
    }, 300)
  },

  computed: {
    // ...mapState('accounting', ['accountLists']),
    tableData: {
      get(){
        return this.accountLists?.data || []; 
      },
      set(data) {
        return data;
      }
    },

    tableHeader() {
      return [
        {
          text: this.$t('name'),
          value: "name",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('number'),
          value: "number",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('currencyname'),
          value: "currency_code",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('current_balance'),
          value: "current_balance_formatted",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('enabled'),
          value: "enabled",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('actions'),
          value: "actions",
          sortable: false,
          class: "text-uppercase th--text font-weight-bold"
        },
      ];
    },

    transactionTableHeader() {
      return [
        {
          text: this.$t('date'),
          value: "paid_at",
          class: "text-uppercase th--text font-weight-bold"
        },{
          text: this.$t('amount'),
          value: "amount_formatted",
          class: "text-uppercase th--text font-weight-bold"
        },{
          text: this.$t('vendor'),
          value: "vendor",
          class: "text-uppercase th--text font-weight-bold"
        },
      ]
    },

    pagination: {
      get() {
        return this.accountLists.data ? this.accountLists.meta.pagination : {};
      }
    },

    currencyValue: {
      get() {
        return this.selectedAccountInformation?.currency_values ? JSON.parse(this.selectedAccountInformation.currency_values).name : '';
      }
    },

    currencyObj: {
      get() {
        if(this.selectedAccountInformation.currency_code) {
          const selectedCurrency = this.selectedAccountInformation.currency_code;
          return this.currencyList.find(currency => currency.code === selectedCurrency);
        }
        return '';
      }
    },

    transactionLists() {
      return this.accountTransactions?.data || [];
    }
  },

  methods: {
    ...mapActions('accounting', ['getAccountsData']),
    moment,
    async fetchCurrencies() {
      try {
        const response = await AkauntingService.getCurrencies();
        this.currencyList = response?.data?.data?.data || [];
      } catch(error) {
        //
      }
    },

    onPaginationClick(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchAccountsData()
    },

    onChangePageLimit() {
      this.currentPage = 1;
      this.fetchAccountsData();
    },

    async fetchAccountsData() {
      if(this.isAccountsDataLoading) {
        return;
      }
      this.isAccountsDataLoading = true;
      try {
        const data = await this.getAccountsData({
          page: this.currentPage,
          limit: this.pageLimit,
          search: this.searchText || '',
        });
        this.accountLists = data; 
        this.isAccountsDataLoading = false;
      } catch(error) {
        this.isAccountsDataLoading = false;
        apiErrorMessage(error);
      }
    },

    onToggleAccountForm(options = {}) {
      this.isEditMode = false;
      this.selectedServiceData = {};
      this.showFormDialog = !this.showFormDialog;
      if(options.created || options.updated) {
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'success',
					message: options.message,
				};
				this.showSnackbar = true;
        this.showInfoModal = false;
        this.fetchAccountsData();
      }
    },

    onSelectEditAccountData(data = {}) {
      this.selectedAccountData = JSON.parse(JSON.stringify(data));
      this.isEditMode = true;
      this.showFormDialog = true;
    },

    onSelectAccountToDelete(data = {}) {
      this.selectedDataToDelete = JSON.parse(JSON.stringify(data));
      this.onToggleDeleteAccountForm();
    },

    onToggleDeleteAccountForm(options = {}) {
      this.showDeleteModal = !this.showDeleteModal;

      if(options.deleted) {
        this.snackbarOption = {
					icon: 'mdi-delete',
					color: 'red',
					message: options.message || 'Deleted.',
				};
				this.showSnackbar = true;
        this.showInfoModal = false;

        this.fetchAccountsData();
      }
    },

    onSelectAccountInformation(data) {
      this.selectedAccountInformation = data;
      this.onToggleAccountInformationModal();
    },

    onToggleAccountInformationModal() {
      this.showInfoModal = !this.showInfoModal;
      if(!this.showInfoModal) {
        this.selectedAccountInformation = {};
      } else {
        this.getAccountTransactions();
      }
    },

    async getAccountTransactions() {
      if(this.isFetchingTransactions) {
        return;
      }
      this.isFetchingTransactions = true;
      try {
        const id = this.selectedAccountInformation.id;
        const response = await AkauntingService.getAccountTransactions(id);
        this.accountTransactions = response.data?.data || [];
        this.isFetchingTransactions = false;
      } catch(error) {
        this.isFetchingTransactions = false;
        apiErrorMessage(error);
      }
    },

    async onChangeEnabledValue(data) {
      if(this.selectedAccountSyncingIds.includes(data.id)) {
        return;
      }
      this.selectedAccountSyncingIds.push(data.id);
      try {

        const isEnabled = !data.enabled;

        const response = isEnabled ? await AkauntingService.enableAccount(data.id) : await AkauntingService.disableAccount(data.id)

        if(response.status === 200) {
          this.tableData = this.tableData.map(record => {
            if(record.id === data.id) {
              data.enabled = isEnabled;
            }
            return data;
          });
        }
        
      } catch(error) {
        apiErrorMessage(error);
      } finally {
        this.selectedAccountSyncingIds = this.selectedAccountSyncingIds.filter(id => id !== data.id);
      }
    }
    
  }
};
</script>


<style lang="scss" scoped>
.vue-app {
  background: none;
}

.v-tabs {
	border-bottom: 1px solid #ddd;

	.v-tab {
		color: #0889a0;
	}
}

.container {
  background-color: none;
}

.w-100 {
  width: 100%;
}

.v-list-item {
  min-height: 36px;
}
</style>
<style lang="scss">
  .v-table-middle-align {
    td, th {
      vertical-align: middle !important;
    }
  }
	.dialog {
		z-index: 999;
	}
  .select-page-limit {
    width: 80px;
  }
</style>
