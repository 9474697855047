<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      max-width="680"
      origin="top center"
      class="dialog pa-0"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="pa-0 z-index-front">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>{{ isEditMode ? $t('edit_account') : $t('add_account') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
					<v-container class="grey lighten-4 pa-0">
						<v-form ref="accountForm">
              <v-card
                class="pa-0 grey lighten-4"
                outlined
                tile
                :loading="isSaving"
              >
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <v-card flat class="">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-name">{{ $t('name') }}</label>
                            <v-text-field
                              v-model="formData.name"
                              :label="$t('account_name_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              required
                              dense
                              id="formdata-name"
                              hide-details
                              :rules="[(v) => !!v || $t('field_required')]"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-tax-number">{{ $t('number') }}</label>
                            <v-text-field
                              v-model="formData.number"
                              :label="$t('account_number_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              required
                              dense
                              id="formdata-tax-number"
                              hide-details
                              :rules="[(v) => !!v || $t('field_required')]"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-currency">{{ $t('currencyname') }}</label>
                            <v-select
                              v-model="formData.currency_code"
                              :items="currencyList"
                              :label="$t('account_currency_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              dense
                              id="formdata-currency"
                              item-text="name"
                              item-value="code"
                              hide-details
                              :rules="[(v) => !!v || $t('field_required')]"
                            ></v-select>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-opening_balance">{{ $t('opening_balance') }}</label>
                            <v-text-field
                              v-model="formData.opening_balance"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              dense
                              id="formdata-opening_balance"
                              hide-details
                              type="number"
                            >
                              <template v-slot:prepend-inner>
                                <div class="d-flex align-center">
                                  <!-- <v-icon>mdi-scale-balance</v-icon>
                                  <v-spacer>&nbsp;</v-spacer> -->
                                  <span class="currency">{{ currencySymbol }}</span>
                                </div>
                              </template>
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-bank_name">{{ $t('bank_name') }}</label>
                            <v-text-field
                              v-model="formData.bank_name"
                              :label="$t('account_bank_name_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              dense
                              id="formdata-bank_name"
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-bank_phone">{{ $t('bank_phone') }}</label>
                            <v-text-field
                              v-model="formData.bank_phone"
                              :label="$t('account_bank_phone_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              dense
                              id="formdata-bank_phone"
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <label class="form-label text-uppercase" for="formdata-bank_address">{{ $t('bank_address') }}</label>
                            <v-textarea
                              v-model="formData.bank_address"
                              solo
                              :label="$t('account_bank_address_input')"
                              class="app-theme-input-border"
                              flat
                              id="formdata-bank_address"
                              rows="2"
                              outlined
                              hide-details
                            ></v-textarea>
                          </v-col>

                          <v-col cols="12">
                            <div class="d-flex">
                              <!-- <div>
                                <label class="form-label text-uppercase">{{ $t('default_account') }}</label>
                                <div>
                                  <v-btn small rounded outlined :color="formData.default_account ? 'success': 'error'" class="pa-4" @click="onToggleDefaultAccount">
                                    <div v-if="formData.default_account" class="d-flex align-center justify-space-around" style="min-width: 60px">
                                      {{ $t('yes') }}
                                      <v-icon>mdi-check-circle</v-icon>
                                    </div>
                                    <div v-else class="d-flex align-center justify-space-around" style="min-width: 60px">
                                      {{ $t('no') }}
                                      <v-icon>mdi-minus-circle</v-icon>
                                    </div>
                                  </v-btn>
                                </div>
                              </div> -->
                              <div>
                                <label class="form-label text-uppercase">{{ $t('enabled') }}</label>
                                <div>
                                  <v-btn small rounded outlined :color="formData.enabled ? 'success': 'error'" class="pa-4" @click="onToggleEnabled">
                                    <div v-if="formData.enabled" class="d-flex align-center justify-space-around" style="min-width: 60px">
                                      {{ $t('yes') }}
                                      <v-icon>mdi-check-circle</v-icon>
                                    </div>
                                    <div v-else class="d-flex align-center justify-space-around" style="min-width: 60px">
                                      {{ $t('no') }}
                                      <v-icon>mdi-minus-circle</v-icon>
                                    </div>
                                  </v-btn>
                                </div>
                              </div>

                            </div>
                          </v-col>
                          
                        </v-row>

                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card>
						</v-form>
					</v-container>
				</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-start">
          <v-btn @click="onSaveForm" class="text-capitalize btn-primary" :loading="isSaving" :disabled="isSaving" v-if="!isEditMode">{{  $t("save") }}</v-btn>
          <v-btn @click="onSaveForm" class="text-capitalize btn-primary" :loading="isSaving" :disabled="isSaving" v-if="isEditMode">{{ $t("update") }}</v-btn>
					<v-btn outlined text class="text-capitalize primary--text" @click="onClose" :disabled="isSaving">{{ $t('cancel') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-snackbar
        timeout="2000"
        vertical
        :color="snackbarOption.color"
        v-model="showSnackbar"
        bottom
      >
      <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>

// import countries from '../data/world-countries/countries/en/countries.json';
// import countryflags from '../data/world-countries/flags/16x16/flags-16x16.json';

import { mapActions, mapState, mapGetters } from 'vuex';

import AkauntingService from '@/services/akaunting/akaunting.service';

export default {
  components: {
  },
  props: ["open", "isEditMode", "formValues"],
	data() {
		return {
			formData: {
				name: '',
        number: '',
        currency_code: '',
        opening_balance: 0,
        bank_name: '',
        bank_phone: '',
        bank_address: '',
        enabled: 1,
        // default_account: 0,
			},
      defaultFields: {},
      isSaving: false,
      showSnackbar: false,
      snackbarOption: {},
      currencyList: [],
		}
	},

	created() {
    this.defaultFields = {...this.formData};
    this.getCurrencies();
	},

	watch: {
		formValues(values) {
			if(values) {

        const {
          id,
          name,
          number,
          currency_code,
          opening_balance,
          bank_name,
          bank_phone,
          bank_address,
          enabled,
          // default_account,
        } = values;

				this.formData = {
          id,
          name,
          number,
          currency_code,
          opening_balance,
          bank_name,
          bank_phone,
          bank_address,
          enabled: enabled ? 1 : 0,
          // default_account,
        };

			} else {
				this.formData = {
				};
			}
		},
	},

  computed: {
    ...mapState('accounting', ['currencies']),
    ...mapGetters('accounting', ['isQBOEnabled']),

    currencySymbol: {
      get() {
        if(this.formData.currency_code) {
          const selectedCurrency = this.formData.currency_code;
          return this.currencyList.find(currency => currency.code === selectedCurrency)?.symbol;
        }
        return '';
      }
    },

    showDialog: {
      get() {
        return this.open;
      },
    },

  },

  methods: {
    ...mapActions('accounting', ['createAccountForm', 'updateAccountForm']),

    async getCurrencies() {
      try {
        const response = await AkauntingService.getCurrencies();
        this.currencyList = response?.data?.data?.data || [];
      } catch(error) {
        //
      }
    },

    onToggleEnabled() {
      const enabled = !this.formData.enabled;
      this.formData = {
        ...this.formData,
        enabled: enabled ? 1 : 0,
      }
    },

    /* onToggleDefaultAccount() {
      const default_account = !this.formData.default_account;
      this.formData = {
        ...this.formData,
        default_account: default_account ? 1 : 0,
      }
    }, */

    onClose() {
			this.formData = {
				...this.defaultFields,
			};
      this.$refs.accountForm.reset();
      this.$emit("toggle");
    },

    async onSaveForm() {
      if(this.isSaving) {
				return;
			}

			const validated = this.$refs.accountForm.validate();
			if(validated) {
				this.isSaving = true;
				try {

          const form = {...this.formData, qbo_enabled: this.isQBOEnabled};
					const { data } = this.isEditMode ? await this.updateAccountForm(form) : await this.createAccountForm(form);

					const message = data.message || 'Data was successfully saved.';
					this.snackbarOption = {
						icon: 'mdi-check',
						color: 'success',
						message,
					};

					this.$refs.accountForm.reset();
          this.$emit("toggle", { created: true, message });
				} catch(error) {

					const { data } = error.response || { data: {  } };
					
					this.snackbarOption = {
						icon: 'mdi-alert-circle',
						color: 'error',
						message: data.message || "Could not save the data.",
					};

				} finally {
					this.showSnackbar = true;
					this.isSaving = false;
				}
			}
    }
  },
};
</script>

<style lang="scss" scoped>
	$button-bg-color: #0171a1;
	$form-label: #819FB2;
	.dialog {
		z-index: 9999;
	}
	.form-label{ 
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	hr {
		border-color: #ebf1f5;
	}

	th {
		color: $form-label;
		font-weight: bold;
	}
	.btn-primary {
		background-color: $button-bg-color !important;
		color: #fff !important;
	}
	.border-dashed {
		border: 1px dashed $form-label !important;
	}
  .z-index-front {
    z-index: 1;
  }

  /* .currency {
    font-size: 1rem;
  } */
</style>
<style lang="scss">
	.app-theme-input-border{
		.v-input__slot {
			border: 1px solid #b5d0e1 !important;
		}
		label {
			color: #a3c6dd !important;
		}
	}
</style>